import Cookies from "js-cookie";

export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.server) {
    const isAuthenticated = Cookies.get("token");

    if (isAuthenticated) {
      return navigateTo("/");
    }
    return;
  }
});
